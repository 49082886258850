import { UrlType } from '@sport1/types/web'

// Delete when exatlon data will be providede by BE
export const TEMPORERY_EXATLON_OBJECT = {
    title: 'Exatlon',
    iconUrl: 'https://reshape.sport1.de/c/t/b976be99-5914-4165-a6a4-6aa707b7a456/:widthx:height',
    href: 'https://www.sport1.de/channel/exatlon',
    id: Math.floor(Math.random() * 1000000000000 + 1),
    contextType: 'TAG',
    componentKey: 'Exatlon',
    hrefTarget: '_self',
    iconUrlType: 'ICON',
    tagUrlType: 'TAG_INFO',
    url: 'https://www.sport1.de/channel/exatlon',
    urlType: UrlType.EXTERNAL,
    contextId: String(Math.floor(Math.random() * 1000000000000 + 1)),
} as const

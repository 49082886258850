export enum SLOTS_WITH_FALLBACK_ENUM {
    banner = 'banner',
    rectangle = 'rectangle',
    topmobile = 'topmobile',
}
export interface AdDetails {
    url: string
    rectangle: string
    banner: string
    topmobile: string
}

const fallbacksHrefs = {
    bundesligaHighlights: 'https://www.sport1.de/tv-video/playlisten/fussball/bundesliga',
    topspiel2Bundesliga:
        'https://www.sport1.de/news/fussball/2-bundesliga/2024/07/2-bundesliga-die-samstags-top-spiele-live-bei-sport1-im-free-tv',
    frauenBundesliga:
        'https://www.sport1.de/news/fussball/frauen-bundesliga/2023/09/frauen-bundesliga-das-topspiel-der-woche-immer-live-auf-sport1',
    doppelpass:
        'https://www.sport1.de/news/fussball/bundesliga/2024/08/stahlwerk-doppelpass-auf-sport1-alle-gaste-und-themen',
    dopaOnTour: 'https://www.printyourticket.de/Tour/DOPPELPASS-11559.html',
    bayernNewsletter: 'https://www.sport1.de/news/unternehmen/2023/08/newsletter-fc-bayern-munchen',
    bvbNewsletter: 'https://www.sport1.de/news/unternehmen/2023/08/newsletter-borussia-dortmund',
    dopaNewsletter: 'https://www.sport1.de/news/unternehmen/2023/08/newsletter-doppelpass',
    dartsNewsletter: 'https://www.sport1.de/news/unternehmen/2023/08/newsletter-anmeldungen-2023',
}

const fallbackSizes = {
    [SLOTS_WITH_FALLBACK_ENUM.rectangle]: { width: '300px', height: '250px' },
    [SLOTS_WITH_FALLBACK_ENUM.topmobile]: { width: '320px', height: '50px' },
    [SLOTS_WITH_FALLBACK_ENUM.banner]: { width: '728px', height: '90px' },
}

const adFallbacks: AdDetails[] = [
    {
        url: fallbacksHrefs.bundesligaHighlights,
        rectangle: 'https://reshape.sport1.de/c/t/9df4e1b0-fa97-4b29-af0f-691042376698/0x0',
        topmobile: 'https://reshape.sport1.de/c/t/7d11fffb-d377-4897-84f2-79c589840a31/0x0',
        banner: 'https://reshape.sport1.de/c/t/75d5f23f-cdda-4c94-b0eb-be30523afe80/0x0',
    },
    {
        url: fallbacksHrefs.topspiel2Bundesliga,
        rectangle: 'https://reshape.sport1.de/c/t/8233e170-a49d-4fa1-83b2-a410626ebb56/0x0',
        topmobile: 'https://reshape.sport1.de/c/t/b6580bc5-8358-4e5c-9809-cb5f44b433ba/0x0',
        banner: 'https://reshape.sport1.de/c/t/f02cfba8-ea8e-4a26-bda9-085c173026b5/0x0',
    },
    {
        url: fallbacksHrefs.frauenBundesliga,
        rectangle: 'https://reshape.sport1.de/c/t/57ca2503-739d-4b3c-8618-07d54138414e/0x0',
        topmobile: 'https://reshape.sport1.de/c/t/a5db899d-d30a-49bd-bfe4-0b95de67643c/0x0',
        banner: 'https://reshape.sport1.de/c/t/f2bb2cd4-2260-4cac-8178-db84ab6213e9/0x0',
    },
    {
        url: fallbacksHrefs.doppelpass,
        rectangle: 'https://reshape.sport1.de/c/t/cbe853e0-843b-4a8d-b8ab-efbe14fc0422/0x0',
        topmobile: 'https://reshape.sport1.de/c/t/f5aef72c-7f7e-4a99-a22b-77b81af9c8f6/0x0',
        banner: 'https://reshape.sport1.de/c/t/e6eee99c-b19e-4995-b54c-e36c0656a68e/0x0',
    },
    {
        url: fallbacksHrefs.dopaOnTour,
        rectangle: 'https://reshape.sport1.de/c/t/a019d153-5137-469a-94b5-3226d14ab1f6/0x0',
        topmobile: 'https://reshape.sport1.de/c/t/db29fa3d-6560-4fe5-a47c-3aac479cc2ec/0x0',
        banner: 'https://reshape.sport1.de/c/t/d8f91972-f9f3-4cfd-942e-1a0c7ae93c9a/0x0',
    },
    {
        url: fallbacksHrefs.bayernNewsletter,
        rectangle: 'https://reshape.sport1.de/c/t/96472158-8edf-4c34-b6b2-68e2c4776ea9/0x0',
        topmobile: 'https://reshape.sport1.de/c/t/bb18e9b8-cc98-4d96-8e63-3379fc83c73a/0x0',
        banner: 'https://reshape.sport1.de/c/t/c8f43938-26c1-406c-ade1-da5ffb18a4fe/0x0',
    },
    {
        url: fallbacksHrefs.bvbNewsletter,
        rectangle: 'https://reshape.sport1.de/c/t/0519d64c-27e5-4902-abd1-3178105a7869/0x0',
        topmobile: 'https://reshape.sport1.de/c/t/303450d7-075f-4fd7-bd05-fc01f1c4b3e9/0x0',
        banner: 'https://reshape.sport1.de/c/t/a5648d64-bd2e-42aa-82be-79dc42cd75e1/0x0',
    },
    {
        url: fallbacksHrefs.dopaNewsletter,
        rectangle: 'https://reshape.sport1.de/c/t/13ff6f2b-52d3-4b0a-95a8-d43deecdb1a4/0x0',
        topmobile: 'https://reshape.sport1.de/c/t/296f6533-867b-4e92-bc19-ec765287b72b/0x0',
        banner: 'https://reshape.sport1.de/c/t/e251d34b-cfb3-44ab-a8cc-18719416e59a/0x0',
    },
    {
        url: fallbacksHrefs.dartsNewsletter,
        rectangle: 'https://reshape.sport1.de/c/t/e0718312-7761-44e3-b7b4-aa3b417a8df8/0x0',
        topmobile: 'https://reshape.sport1.de/c/t/f2226e0d-27c8-41f1-9ad9-814777aaecb9/0x0',
        banner: 'https://reshape.sport1.de/c/t/cc3ddaaa-ce5b-4765-9877-e734ac7b9168/0x0',
    },
]

export { adFallbacks, fallbackSizes }

import React, { FC, ReactNode, memo } from 'react'
import Link, { LinkProps } from 'next/link'
import Touchable, { MinifiedTouchableProps } from '@sport1/news-styleguide/Touchable'

type WrappedTouchableProps = MinifiedTouchableProps &
    LinkProps & { children?: ReactNode; testID?: string }

const WrappedTouchable: FC<WrappedTouchableProps> = ({ href, children, ...rest }) => {
    if (href)
        return (
            <Link href={href} passHref legacyBehavior>
                <Touchable {...rest}>{children}</Touchable>
            </Link>
        )

    return <Touchable {...rest}>{children}</Touchable>
}

export default memo(WrappedTouchable)

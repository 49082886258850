import React, { FC, useCallback, useEffect, useRef } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { SystemSpacingsProps } from '@sport1/news-styleguide/Types'
import { useMobile } from '@/hooks/useMobile'
import Config from '@/utils/Config'
import { isPureActive } from '@/components/ThirdParty/Piano/util'

export type DisplayStickyAdProps = SystemSpacingsProps & {
    /**
     * The placement name of the ad, e.g. sky, banner, rectangle
     */
    placement?: string

    /**
     * The placement id of the ad, e.g. banner, banner2, rectangle, rectangle2
     */
    placementId?: string
}

const DisplayStickyAd: FC<DisplayStickyAdProps> = ({ placement, placementId, ...rest }) => {
    const ref = useRef<HTMLDivElement>(null)
    const loaded = useRef(false)
    const { isMobile } = useMobile()

    const shouldRender = useCallback((): boolean => {
        if (!Config.ADS_ACTIVE || isPureActive()) {
            return false
        }
        return isMobile
    }, [isMobile])

    useEffect(() => {
        if (ref.current && !loaded.current && placementId) {
            window.SDG?.cmd.push(function () {
                window.S1RegisteredSlots = window.S1RegisteredSlots || {}
                if (!window.S1RegisteredSlots[placementId]) {
                    window.S1RegisteredSlots[placementId] = true
                    window.SDG?.Publisher?.registerSlot?.(
                        placementId,
                        ref.current as HTMLDivElement
                    )
                        ?.configure({ pinToBottom: true })
                        ?.load?.()
                } else {
                    // eslint-disable-next-line no-console
                    console.warn(
                        'Did not SDG.Publisher.registerSlot, was registered already',
                        placementId
                    )
                }
                loaded.current = true
            })
        }
    }, [placement, placementId, shouldRender])

    if (!shouldRender()) {
        return null
    }

    return (
        <NonFlexingContainer testID={placement} {...rest}>
            <div
                ref={ref}
                className={`s1-ad s1-ad-name-${placement} s1-ad-${placementId}  text-center`}
            >
                <div id={placementId}></div>
            </div>
            <div className={`s1-debug-ad s1-debug-ad-name-${placement} s1-debug-ad-${placementId}`}>
                {JSON.stringify(
                    {
                        placement,
                        placementId,
                    },
                    null,
                    4
                )}
            </div>
        </NonFlexingContainer>
    )
}

export default DisplayStickyAd

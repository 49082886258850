import React from 'react'
import Sport1Logo from '@sport1/news-styleguide/Sport1Logo'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import clsx from 'clsx'
import { useTracking } from '@/utils/tracking/TrackingProvider'
import { useMobile } from '@/hooks/useMobile'

type Props = {
    noLogo?: boolean
    isVisible?: boolean
}

export const LogoSport1: React.FC<Props> = ({ noLogo = false, isVisible = true }) => {
    const { trackInteraction } = useTracking()
    const { isMobile } = useMobile()

    const onSport1LogoPress = (): void => {
        trackInteraction({
            devices: 'all',
            interaction_category: 'navigation',
            interaction_action: 'click',
            interaction_label: 'logo',
            platforms: isMobile ? 'mobile' : 'desktop',
        })
    }

    return (
        !noLogo && (
            <NonFlexingContainer
                centerVertical
                centerHorizontal
                backgroundColor="onyx"
                maxWidth={74}
                marginX="spacing-6"
            >
                <div
                    className={clsx({
                        ['animate-fadeIn']: isVisible,
                    })}
                >
                    <Sport1Logo
                        href="/"
                        onPress={onSport1LogoPress}
                        type="OnDark"
                        width={63}
                        testID="header-banner-sport1-logo"
                    />
                </div>
            </NonFlexingContainer>
        )
    )
}

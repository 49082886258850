import React, { FC, memo } from 'react'
import NavigationListItem, {
    NavigationListItemProps,
} from '@sport1/news-styleguide/NavigationListItem'
import Link, { LinkProps } from 'next/link'

type WrappedNavigationListItemProps = NavigationListItemProps & LinkProps

const WrappedNavigationListItem: FC<WrappedNavigationListItemProps> = ({ href, ...rest }) => {
    if (href)
        return (
            <Link href={href} passHref legacyBehavior>
                <NavigationListItem {...rest} />
            </Link>
        )

    return <NavigationListItem {...rest} />
}

export default memo(WrappedNavigationListItem)

import React, { FC } from 'react'
import { LabelProps, TagProps, TagListProps } from '@sport1/types/web'
import Font from '@sport1/news-styleguide/Font'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { useRouter } from 'next/router'
import WrappedNavigationListItem from '../WrappedNavigationListItem'
import { TEMPORERY_EXATLON_OBJECT } from './types'
import nofollowExternal from '@/utils/url/nofollowExternal'
import { sport1Bold } from '@/helpers/fonts'

type Props = Pick<TagListProps, 'headline' | 'content'> & {
    bold?: boolean
    onTagPress?: (tag: TagProps | LabelProps) => void
    currentTag?: TagProps
}

const TagList: FC<Props> = ({ bold, headline, content, onTagPress, currentTag }) => {
    const { asPath } = useRouter()

    // Delete when exatlon data will be providede by BE
    const temporerySport1OriginalsList: (TagProps | LabelProps)[] =
        currentTag?.title === 'SPORT1 Originals' &&
        !content.find(el => el.title.toLowerCase() === 'exatlon')
            ? [TEMPORERY_EXATLON_OBJECT, ...content]
            : content
    return (
        <NonFlexingContainer spaceBottom="small">
            {headline ? (
                <NonFlexingContainer paddingX="spacing-6" paddingY="spacing-2">
                    <Font
                        fontVariant="Body-L"
                        fontFamilyVariant="Sport1-Bold"
                        nextFontFamilyVariant={sport1Bold.style.fontFamily}
                    >
                        {headline.toUpperCase()}
                    </Font>
                </NonFlexingContainer>
            ) : null}
            {/* Change temporerySport1OriginalsList to content when exatlon data will be providede by BE */}
            {temporerySport1OriginalsList.map((tag, index) => {
                const href = tag.href || ''
                // TODO: https://sport1digital.atlassian.net/browse/ML-6214
                return (
                    <WrappedNavigationListItem
                        key={`${tag.id || tag.contextId}_${index}`}
                        testID={`tag-list-navigation-item-${tag.title}`}
                        name={bold ? tag.title.toUpperCase() : tag.title}
                        count={tag.childrenCount}
                        imageType="image"
                        imageUrl={tag.iconUrl}
                        alt={tag.title}
                        backgroundColor={href === asPath ? 'onyx-opacity-12' : 'pearl'}
                        rightIconColor="keshi-3"
                        href={href}
                        onPress={onTagPress ? () => onTagPress(tag) : undefined}
                        webRel={nofollowExternal}
                        webTarget={tag.hrefTarget}
                    />
                )
            })}
        </NonFlexingContainer>
    )
}

export default TagList

import { LabelProps, TagProps } from '@sport1/types/web'

export const sortNavigationElements = (
    competitionsList: (TagProps | LabelProps)[],
    prioritizedCompetitions: string[]
) => {
    const sortedPrioritizedCompetitions = prioritizedCompetitions.flatMap(
        prioritizedCompetition =>
            competitionsList.find(
                competition => competition.title.toLowerCase() === prioritizedCompetition
            ) || []
    )

    const restOfCompetitions = competitionsList
        .flatMap(competition => {
            if (!prioritizedCompetitions.includes(competition.title.toLowerCase())) {
                return competition
            }
            return []
        })
        .sort((a, b) => a?.title.localeCompare(b.title))

    return [...sortedPrioritizedCompetitions, ...restOfCompetitions]
}

import React, { Dispatch, SetStateAction, useCallback, useTransition } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import clsx from 'clsx'
import FontelloIcon from '@sport1/news-styleguide/FontelloIcon'
import Touchable from '@sport1/news-styleguide/Touchable'
import { TabbarConfig } from '../TabbarConfig'

interface BurgerProps {
    setIsBurgerMenuOpen: Dispatch<SetStateAction<boolean>>
    isVisible: boolean
    side?: 'Left' | 'Right'
}

const Burger = ({ setIsBurgerMenuOpen, isVisible, side }: BurgerProps) => {
    const [, startTransition] = useTransition()

    const onBurgerMenuIconPress = useCallback(() => {
        startTransition(() => setIsBurgerMenuOpen(true))
    }, [setIsBurgerMenuOpen])

    return (
        <NonFlexingContainer
            centerHorizontal
            centerVertical
            width={TabbarConfig.BURGER_WIDTH}
            backgroundColor="onyx"
            data-testid={`${side ?? ''} burger`}
            className={side === 'Left' ? 'order-none' : 'order-last'}
        >
            <div
                className={clsx({
                    ['animate-fadeInFromLeft']: isVisible,
                })}
            >
                <Touchable testID="burger-menu-icon" onPress={() => onBurgerMenuIconPress()}>
                    <NonFlexingContainer centerHorizontal centerVertical>
                        <FontelloIcon name="burger-menu" color="pearl" fontSize="20px" />
                    </NonFlexingContainer>
                </Touchable>
            </div>
        </NonFlexingContainer>
    )
}

export default Burger

import React from 'react'
import { Access, PianoAPIStructure, showObj, UserAccess, userData } from '@/types/piano'
import Config from '@/utils/Config'
import postDisclosureOnlyConsent from '@/utils/consent/postDisclosureOnlyConsent'
import { clearAllCookies, readCookie, writeCookie } from '@/utils/cookie'

/**
 * https://docs.piano.io/offer-template-essentials/#startcheckout
 * https://docs.piano.io/callbacks/#startevent
 */

export const PIANO_COOKIE_NAME = 'pianoAccount'

export const callPianoApi = (callback: () => void) => {
    if (typeof window !== 'undefined') {
        const fallback = [] as unknown as PianoAPIStructure
        window.tp = window.tp || fallback
        callback()
    }
}

export const pianoApiCallInit = (callback: () => void) => {
    if (typeof window !== 'undefined') {
        window.tp?.push(['init', callback])
        window.tp?.push(LogoutListener)
    }
}

export const isPianoUserLoggedIn = (): boolean =>
    (typeof window !== 'undefined' &&
        (window?.tp?.pianoId?.isUserValid() || readCookie(PIANO_COOKIE_NAME) !== undefined)) ||
    false

export const isPureActive = (): boolean =>
    (typeof window !== 'undefined' && window.S1_PIANO) || false

export const ids =
    Config.PIANO_COMPOSER_ENVIRONMENT === 'sandbox'
        ? {
              offerId: 'OF7N0KDRS16G',
              templateId: 'OTLG6IM7TF7I',
              termId: 'TM3ICKC5R4G7',
              rid: 'RLG8GWY',
          }
        : {
              offerId: 'OFXN8UX8EEGN',
              templateId: 'OTZ1P360SK4E',
              termId: 'TM3676RS3S0C',
              rid: 'RHEC0WJ',
          }

/**
 * Piano is not allowed to be loaded without consent!
 * Exception: It is allowed to load Piano when the user tries to log in or register or reset password.
 */
export const loadPiano = () => {
    if (typeof window !== 'undefined') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({ event: 'cmp_piano_composer' })
    }
}

/**
 * https://docs.piano.io/javascript-offer/
 */
const openCheckout = () => {
    window.tp?.offer?.show({
        offerId: ids.offerId,
        templateId: ids.templateId,
        termId: ids.termId,
        close: () => {
            location.reload()
        },
        complete: () => {
            authenticateUser()
        },
    })
}

export const pianoOpenCheckout = () => {
    callPianoApi(() => {
        openCheckout()
    })
}

const onDisplayHandleClose = () => {
    const closeButton = document.querySelector('button.tp-close')
    closeButton?.addEventListener('click', () => {
        // tracking?
        location.reload()
    })
}

const pianoOpenScreen = ({
    screen,
    pianoEvents,
}: {
    screen: 'login' | 'register'
    pianoEvents: showObj
}) => {
    callPianoApi(() => {
        pianoApiCallInit(() => {
            if ('register' === screen && window.tp?.user?.isUserValid()) {
                openCheckout()
            } else {
                window.tp?.pianoId?.show({
                    screen,
                    ...pianoEvents,
                })
            }
        })
    })
}

export const pianoUserRegister = () => {
    loadPiano()
    pianoOpenScreen({
        screen: 'register',
        pianoEvents: {
            registerDisplayed: onDisplayHandleClose,
            registrationSuccess: () => {
                openCheckout()
            },
            loggedIn: () => {
                authenticateUser((_, access) => {
                    const hasPure = access?.access?.granted && access.access.access_id
                    if (!hasPure) {
                        openCheckout()
                    } else {
                        location.reload()
                    }
                })
            },
        },
    })
}

export const pianoUserLogin = ({
    loginStateCallback,
    shouldReload = true,
}: {
    loginStateCallback?: React.Dispatch<React.SetStateAction<boolean>>
    shouldReload?: boolean
}) => {
    loadPiano()
    pianoOpenScreen({
        screen: 'login',
        pianoEvents: {
            loggedIn: () => {
                authenticateUser((_, access) => {
                    if (loginStateCallback) {
                        loginStateCallback(true)
                    }

                    const hasPure = !!(access?.access?.granted && access.access.access_id)
                    if (!hasPure) {
                        openCheckout()
                    } else if (shouldReload) {
                        location.reload()
                    }
                })
            },
            loginDisplayed: onDisplayHandleClose,
        },
    })
}

export const pianoUserLogout = ({
    loginStateCallback,
}: {
    loginStateCallback?: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    callPianoApi(() => {
        pianoApiCallInit(() => {
            window.tp?.pianoId?.logout(() => {
                if (loginStateCallback) {
                    loginStateCallback(isPianoUserLoggedIn())
                }
            })
        })
    })
}

export const loadUserAPI = (callback: (user: userData, access?: Access) => void) => {
    window.tp?.pianoId?.loadExtendedUser({
        extendedUserLoaded: user => {
            if (user) {
                window.tp?.api?.callApi('/access/check', { rid: ids.rid }, (access: Access) => {
                    callback(user, access)
                })
            }
        },
    })
}

export const authenticateUser = (callback?: (user: userData, access?: Access) => void) => {
    loadUserAPI((user, access) => {
        saveUser({
            email: user.email,
            accessList: access?.access?.granted ? [ids.rid] : [],
        })
        if (access?.access?.granted) {
            postDisclosureOnlyConsent(() => {
                callback?.(user, access)
            })
        } else {
            callback?.(user, access)
        }
    })
}

export const saveUser = (user: UserAccess) => {
    writeCookie({
        name: PIANO_COOKIE_NAME,
        value: btoa(`${user.email}|${user.accessList.join(',')}`),
        expireDate: { value: 24, unit: 'days' },
    })
}

export const readUser = (): UserAccess | undefined => {
    const value = readCookie(PIANO_COOKIE_NAME)
    if (value) {
        try {
            const [email, accessListString = ''] = atob(value).split('|')
            return {
                email,
                accessList: accessListString.split(','),
            }
        } catch (error) {}
    }
}

// https://docs.piano.io/callbacks/#logout
export const LogoutListener: [string, string, Function] = [
    'addHandler',
    'logout',
    () => {
        // eslint-disable-next-line no-console
        console.debug('render-test: LogoutListener Piano util')
        clearAllCookies()
        location.reload()
    },
]

import React, { CSSProperties, FC, memo } from 'react'

type TabbableAreaProps = {
    tabIndex?: number
    style?: CSSProperties
    activateBlur?: boolean
    onBlur?: () => void
    testID?: string
    children?: React.ReactNode
}

/**
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex
 */
const TabbableArea: FC<TabbableAreaProps> = ({
    testID,
    children,
    tabIndex = 0,
    style = {},
    activateBlur,
    onBlur,
}) => {
    return (
        <div
            data-testid={testID}
            style={{
                outline: 'none',
                ...style,
            }}
            tabIndex={tabIndex}
            onBlur={activateBlur ? onBlur : undefined}
        >
            {children}
        </div>
    )
}

export default memo(TabbableArea)

import React, {
    Dispatch,
    FC,
    memo,
    ReactElement,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Tab from '@sport1/news-styleguide/Tab'
import FlexingContainer from '@sport1/news-styleguide/FlexingContainer'
import { useRouter } from 'next/router'
import { LabelProps, TagProps } from '@sport1/types/web'
import {
    createTabBarRuler,
    getRulerMaxIndex,
    getDropdownOverlayItems,
    getUrlLastSlug,
    renderOverlayItem,
    renderTabBarItems,
} from '../utils'
import Burger from '../Burger'
import { mostPopularCompetitions } from './constants'
import { sortNavigationElements } from './utils'
import { ArrowDropdown } from '@/components/TabBar/DropdownArrow'
import { TabbarConfig } from '@/components/TabBar/TabbarConfig'
import { RefElementType, TabBarItemType } from '@/components/TabBar/TabbarTypes'
import DropDownOverlay, { DropDownRenderItemProps } from '@/components/DropDownOverlay'
import { slugify } from '@/helpers/slugHelper'
import useOuterClick from '@/hooks/useOuterClick'
import { useTracking } from '@/utils/tracking/TrackingProvider'
import nofollowExternal from '@/utils/url/nofollowExternal'
import { useMobile } from '@/hooks/useMobile'

export type CompetitionsTabBarProps = {
    items: (TagProps | LabelProps)[]
    staticTabName?: string
    backgroundColor?: 'onyx' | 'pearl'
    setIsBurgerMenuOpen?: Dispatch<SetStateAction<boolean>>
    isVisible?: boolean
}

const CompetitionsTabBar: FC<CompetitionsTabBarProps> = ({
    items,
    staticTabName,
    backgroundColor = 'onyx',
    setIsBurgerMenuOpen,
    isVisible,
}) => {
    const {
        query: { slug },
    } = useRouter()
    const parentTabRef = useRef<RefElementType | null>(null)
    const newsTabRef = useRef<RefElementType | null>(null)
    const containerRef = useRef<RefElementType | null>(null)
    const [selectedTab, setSelectedTab] = useState(-1)
    const [showDropdown, setShowDropdown] = useState<boolean>(false)
    const wrapperRef = useRef(null)
    const { isMobile } = useMobile()
    const { trackInteraction } = useTracking()
    useOuterClick(wrapperRef, () => setShowDropdown(false))
    const prioritizedCompetitions =
        mostPopularCompetitions?.[(slug as string)[0] as keyof typeof mostPopularCompetitions]

    const sortedNavigationElements = prioritizedCompetitions
        ? sortNavigationElements(items, prioritizedCompetitions)
        : items

    const tabbarItems = useMemo(() => {
        return sortedNavigationElements.map((item: TagProps | LabelProps) => {
            const { title } = item

            return {
                title,
                href: item.href,
                contextId: item.contextId,
            } as TabBarItemType
        })
    }, [sortedNavigationElements])
    const [numberItemsRendered, setNumberCompetitionsRendered] = useState(tabbarItems.length)

    const getTabBarItems = useCallback(
        (tabBarItems: TabBarItemType[]) => {
            // Create Ruler
            const ruler = createTabBarRuler()
            containerRef.current?.appendChild(ruler)

            // Get cointaner width
            const containerWidth = containerRef.current?.getBoundingClientRect().width || 0

            // Get Channel Tab width
            const staticTabsWidth =
                [parentTabRef, newsTabRef]?.reduce(
                    (acc, tab) => acc + (tab.current?.getBoundingClientRect().width || 0),
                    0
                ) || 0

            // Get Static width of static items in the container
            const staticWidth = staticTabsWidth + TabbarConfig.DROPDOWN_ARROW_WIDTH

            // Get index last item that doesn't overflow container
            const maxIndex = getRulerMaxIndex(tabBarItems, ruler, containerWidth, staticWidth)

            // Remove ruler from DOM
            ruler.parentNode?.removeChild(ruler)

            // Set maxim number of items rendered
            setNumberCompetitionsRendered(maxIndex !== undefined ? maxIndex : tabBarItems.length)
        },
        [containerRef, parentTabRef, newsTabRef]
    )

    useEffect(() => {
        getTabBarItems(tabbarItems)
        window.addEventListener('resize', () => getTabBarItems(tabbarItems))
        return () => {
            window.removeEventListener('resize', () => getTabBarItems(tabbarItems))
        }
    }, [tabbarItems, getTabBarItems])

    useEffect(() => {
        setShowDropdown(false)
    }, [isVisible])

    const trackPress = useCallback(
        (index: number, tag?: TagProps | LabelProps) => {
            const selectedTabItem = tag || tabbarItems[index]
            if (selectedTabItem) {
                trackInteraction({
                    devices: 'all',
                    interaction_category: 'navigation',
                    interaction_action: 'click',
                    interaction_label: `competitions-navbar_${slugify(selectedTabItem.title)}`,
                    platforms: isMobile ? 'mobile' : 'desktop',
                })
            }
        },
        [isMobile, tabbarItems, trackInteraction]
    )

    const callRenderOverlayItem = useCallback(
        ({ tag, index, href }: DropDownRenderItemProps): ReactElement => {
            const selectedItemIndex = selectedTab - numberItemsRendered
            return renderOverlayItem({
                tag,
                currentTagIndex: index,
                selectedItemIndex,
                href,
                onPress: () => trackPress(index, tag),
            })
        },
        [selectedTab, numberItemsRendered, trackPress]
    )

    const onTabPress = useCallback(
        (index: number) => {
            setSelectedTab(index)
            trackPress(index)
        },
        [trackPress]
    )

    const renderTabBars = useMemo(() => {
        return renderTabBarItems({
            tabVariant: 'secondary',
            tabBarItems: tabbarItems,
            numberItemsRendered,
            onTabPress,
            selectedTab,
            uppercase: false,
        })
    }, [tabbarItems, numberItemsRendered, onTabPress, selectedTab])

    return (
        <NonFlexingContainer
            horizontal
            innerRef={containerRef}
            elevation={2}
            testID="TabBar-CompetitionsTabBar"
            backgroundColor={backgroundColor}
            paddingX={['spacing-none', 'spacing-none', 'spacing-6']}
        >
            {isVisible && setIsBurgerMenuOpen && (
                <Burger
                    setIsBurgerMenuOpen={setIsBurgerMenuOpen}
                    isVisible={isVisible}
                    side={isMobile ? 'Right' : 'Left'}
                />
            )}
            <NonFlexingContainer display={['flex', 'flex', 'flex', 'none']} innerRef={parentTabRef}>
                <Tab
                    tabVariant="secondary"
                    title={staticTabName || getUrlLastSlug(slug)}
                    href={tabbarItems[0]?.href?.split('/').slice(0, -1).join('/')}
                    webRel={nofollowExternal}
                />
            </NonFlexingContainer>
            <NonFlexingContainer innerRef={newsTabRef}>
                <Tab
                    tabVariant="secondary"
                    title="News"
                    isActive={selectedTab === -1}
                    onPress={() => onTabPress(-1)}
                    testID="tab-bar-tab"
                />
            </NonFlexingContainer>
            <>
                <NonFlexingContainer
                    horizontal
                    overflow="hidden"
                    testID="competitionsTabBar-container"
                >
                    {renderTabBars}
                </NonFlexingContainer>
                <FlexingContainer
                    backgroundColor={backgroundColor}
                    alignRight
                    centerVertical
                    zIndex={30}
                    testID="competitionsTabBar-arrow-container"
                >
                    {numberItemsRendered !== sortedNavigationElements.length && (
                        <ArrowDropdown
                            setShowDropdown={() => setShowDropdown(show => !show)}
                            showDropdown={showDropdown}
                            testID="competitionsTabBar-dropdown-container"
                        />
                    )}
                </FlexingContainer>
                <NonFlexingContainer
                    innerRef={wrapperRef}
                    position="relative"
                    display={showDropdown ? 'flex' : 'none'}
                    testID="competitionsTabBar-dropdown-container-2"
                >
                    <DropDownOverlay
                        tags={getDropdownOverlayItems(tabbarItems, numberItemsRendered)}
                        renderItem={callRenderOverlayItem}
                        top={44}
                        testID="competitionsTabBar-dropdown-overlay"
                    />
                </NonFlexingContainer>
            </>
        </NonFlexingContainer>
    )
}

export default memo(CompetitionsTabBar)

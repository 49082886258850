import React, { FC, ReactElement } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Font from '@sport1/news-styleguide/Font'
import Touchable from '@sport1/news-styleguide/Touchable'
import { LabelProps, TagProps } from '@sport1/types/web'
import { PercentageType, PixelType, ResponsiveType } from '@sport1/news-styleguide/Types'
import { sport1CondensedBlackItalic } from '@/helpers/fonts'

export type DropDownRenderItemProps = {
    tag: TagProps | LabelProps
    index: number
    href?: string
    tags?: (TagProps | LabelProps)[]
}

type DropDownOverlayProps = {
    tags: (TagProps | LabelProps)[]
    top?: ResponsiveType<number | PixelType | PercentageType>
    bottom?: ResponsiveType<number | PixelType | PercentageType>
    left?: ResponsiveType<number | PixelType | PercentageType>
    right?: ResponsiveType<number | PixelType | PercentageType>
    width?: ResponsiveType<number | PixelType | PercentageType>
    maxHeight?: number
    header?: ReactElement
    selectedItem?: number
    renderItem?: (props: DropDownRenderItemProps) => ReactElement
    onMouseOver?: () => void
    onMouseLeave?: () => void
    testID?: string
    backgroundColor?: string
}

const DropDownOverlay: FC<DropDownOverlayProps> = ({
    tags,
    top = '100%',
    bottom,
    left,
    right = 0,
    width = 280,
    maxHeight = 290,
    header,
    renderItem,
    onMouseOver,
    onMouseLeave,
    testID,
    backgroundColor = 'onyx',
}): ReactElement => {
    return (
        <NonFlexingContainer
            testID={testID}
            noExpand
            position="absolute"
            top={top}
            bottom={bottom}
            left={left}
            right={right}
            width={width}
            maxHeight={maxHeight}
            overflow="auto"
            elevation={4}
            backgroundColor={backgroundColor}
            zIndex={100}
            // parentSlug ??
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
        >
            {header}
            {tags.map((tag, index) =>
                renderItem ? (
                    renderItem({
                        tag,
                        index,
                        href: tag.href,
                        tags,
                    })
                ) : (
                    <Touchable key={`${tag.contextId}-${tag.componentKey}`} href={tag.href}>
                        <NonFlexingContainer
                            padding="spacing-6"
                            borderBottom={index !== tags.length - 1 ? 1 : 0}
                        >
                            <Font
                                fontVariant="Body-S"
                                nextFontFamilyVariant={sport1CondensedBlackItalic.style.fontFamily}
                                fontFamilyVariant="Sport1-CondensedBlackItalic"
                                numberOfLines={1}
                                color="keshi-3"
                            >
                                {tag.title}
                            </Font>
                        </NonFlexingContainer>
                    </Touchable>
                )
            )}
        </NonFlexingContainer>
    )
}

export default DropDownOverlay

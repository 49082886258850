import Font from '@sport1/news-styleguide/Font'
import FontelloIcon from '@sport1/news-styleguide/FontelloIcon'
import Sport1Logo from '@sport1/news-styleguide/Sport1Logo'
import Touchable from '@sport1/news-styleguide/Touchable'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import React, { FC, memo } from 'react'
import { sport1CondensedBlackItalic } from '@/helpers/fonts'

type BurgerMenuHeaderProps = {
    title?: string
    onBack?: () => void
    onClose: () => void
}

export const BurgerMenuHeader: FC<BurgerMenuHeaderProps> = ({ title, onBack, onClose }) => (
    <NonFlexingContainer horizontal centerVertical padding="spacing-7">
        {onBack ? (
            <>
                <Touchable testID="burger-menu-back-icon" onPress={onBack}>
                    <FontelloIcon name="scroll-left" color="onyx" fontSize="FS18" />
                </Touchable>
                {title ? (
                    <NonFlexingContainer centerHorizontal width="100%">
                        <Font
                            nextFontFamilyVariant={sport1CondensedBlackItalic.style.fontFamily}
                            fontVariant="Display-S"
                            fontFamilyVariant="Sport1-CondensedBlackItalic"
                        >
                            {title}
                        </Font>
                    </NonFlexingContainer>
                ) : null}
            </>
        ) : (
            <Sport1Logo testID="burger-menu-home-logo" width={70} href="/" />
        )}
        <NonFlexingContainer marginLeft="auto">
            <Touchable testID="burger-menu-close-icon" onPress={onClose}>
                <FontelloIcon name="close-x" color="onyx" fontSize="FS18" />
            </Touchable>
        </NonFlexingContainer>
    </NonFlexingContainer>
)

export default memo(BurgerMenuHeader)

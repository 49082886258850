import { MetaDataPropsData, TagNavigationItemProps } from '@sport1/types/web'
import { GetServerSidePropsContext } from 'next'
import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { getSport1Navigation } from '@/pages/api/cms/navigation'
import { getTeamLayout, getTeamTag } from '@/pages/api/cms/team/[id]'
import handleRejection, { PageNotFound } from '@/utils/errors/handleRejection'
import { TeamPageProps } from '@/types/page'
import { BreadCrumbProps } from '@/layouts/Standard/BreadCrumbs'
import { useNavigation } from '@/utils/navigation/Standard'

const getTeamPageSerSideProps = async ({
    teamId,
    widget,
    context,
}: {
    teamId: string
    widget?: string
    context: GetServerSidePropsContext
}): Promise<TeamPageProps | PageNotFound> => {
    let navigationData, teamTag, layout
    try {
        ;[navigationData, teamTag] = await Promise.all([getSport1Navigation(), getTeamTag(teamId)])
        layout = await getTeamLayout(teamId, widget, teamTag)
    } catch (error) {
        return handleRejection(error, { teamId, widget })
    }

    if (!layout) {
        return { notFound: true }
    }

    if (widget && layout?.layoutData.meta) {
        layout.layoutData.meta.canonical += `/${widget}`
    }

    //TODO: Deeplink for tabs?
    const deeplink = `sport1://team/${teamId}`
    context.res.setHeader('deeplink', deeplink)

    const breadCrumbs: BreadCrumbProps[] = []
    const meta = layout.layoutData.meta as MetaDataPropsData
    meta.seo = meta.seo || []

    if (widget === 'highlights') {
        breadCrumbs.push({ title: `${teamTag.title} Videos` })
    } else if (widget === 'kader') {
        breadCrumbs.push({ title: `${teamTag.title} Kader` })
    } else if (widget === 'news') {
        breadCrumbs.push({ title: `${teamTag.title} News` })
    } else if (widget === 'tabelle') {
        breadCrumbs.push({ title: `${teamTag.title} Tabelle` })
    } else if (widget === 'transfers') {
        breadCrumbs.push({ title: `${teamTag.title} Transfers` })
    } else if (widget === 'statistiken') {
        breadCrumbs.push({ title: `${teamTag.title} Statistiken` })
    }

    return {
        layoutData: layout.layoutData,
        navigation: {
            ...navigationData,
            tag: teamTag,
            breadCrumbs: {
                ...navigationData.breadCrumbs,
                additionalBreadCrumbs: [
                    ...(navigationData?.breadCrumbs?.additionalBreadCrumbs || []),
                    ...breadCrumbs,
                ],
            },
        },
        deeplink,
        widget: widget ?? null,
        team: layout.team,
    }
}

export const useIsOnTeamPage = (): boolean => {
    const { navigation } = useNavigation()
    const { asPath } = useRouter()

    return useMemo(
        () => navigation?.tag?.categoryType === 'TEAM' && asPath.split('/').length <= 4,
        [asPath, navigation?.tag?.categoryType]
    )
}

export const useFindValueInNavigation = (navValue: string): TagNavigationItemProps | undefined => {
    const { navigation } = useNavigation()

    const navigationValue = navigation?.tag?.navigationItems?.find(nav => nav.value === navValue)

    return navigationValue
}

export default getTeamPageSerSideProps

import { useState, useEffect, useRef } from 'react'
import { RefElementType, TabBarItemType } from '@/components/TabBar/TabbarTypes'
import { createTabBarRuler, getRulerMaxIndex } from '@/components/TabBar/utils'

export const useItemsRendered = (
    tabbarItems: TabBarItemType[],
    getAdditionalWidth: (staticTabsWidth: number) => number,
    uppercase = false,
    isVisible?: boolean
) => {
    const containerRef = useRef<RefElementType | null>(null)
    const parentTabRef = useRef<RefElementType | null>(null)
    const [numberItemsRendered, setNumberCompetitionsRendered] = useState(tabbarItems.length)

    useEffect(() => {
        const getTabBarItems = (tabBarItems: TabBarItemType[]) => {
            // Get cointaner width
            const containerWidth = containerRef.current?.getBoundingClientRect().width ?? 0

            // Get Channel Tab width
            const staticTabsWidth = parentTabRef.current?.getBoundingClientRect().width ?? 0

            const staticWidth = getAdditionalWidth(staticTabsWidth)

            // Create Ruler
            const ruler = createTabBarRuler()
            containerRef.current?.appendChild(ruler)

            // Get index last item that doesn't overflow container
            const maxIndex =
                getRulerMaxIndex(tabBarItems, ruler, containerWidth, staticWidth, uppercase) ??
                tabBarItems.length

            // Remove ruler from DOM
            ruler.parentNode?.removeChild(ruler)

            // Set maxim number of items rendered
            setNumberCompetitionsRendered(maxIndex)
        }

        getTabBarItems(tabbarItems)

        const resizeEventListener = () => getTabBarItems(tabbarItems)
        window.addEventListener('resize', resizeEventListener)

        return () => {
            window.removeEventListener('resize', resizeEventListener)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabbarItems, isVisible])

    return {
        containerRef,
        parentTabRef,
        numberItemsRendered,
    }
}

import React, { FunctionComponent, memo } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Font from '@sport1/news-styleguide/Font'
import { NonFlexingContainerProps } from '@sport1/news-styleguide/Types'
import { sport1Regular } from '@/helpers/fonts'

export type FallbackProps = NonFlexingContainerProps & {
    text: string
}

const Fallback: FunctionComponent<FallbackProps> = ({ text, testID, ...props }) => (
    <NonFlexingContainer testID={testID} width="100%" backgroundColor="pearl" {...props}>
        <Font
            fontVariant="Body-L"
            fontFamilyVariant="Sport1-Regular"
            nextFontFamilyVariant={sport1Regular.style.fontFamily}
        >
            {text}
        </Font>
    </NonFlexingContainer>
)

export default memo(Fallback)

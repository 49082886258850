export const mostPopularCompetitions = {
    fussball: [
        'bundesliga',
        '2. bundesliga',
        'champions league',
        '3. liga',
        'dfb-pokal',
        'premier league',
        'frauen-wm',
        'dfb-team',
        'europa league',
        'la liga',
        'em',
        'wm 2022',
        'serie a',
        'frauen-bundesliga',
        'europa conference league',
        'ligue 1',
        'regionalliga bayern',
        'dfb-frauen',
        'regionalliga nordost',
        'süper lig',
        'u21-em',
        'regionalliga west',
        'frauen champions league',
        'regionalliga südwest',
        'relegation bundesliga',
        'regionalliga nord',
        'nations league',
        'afrika-cup',
        'relegation 2. bundesliga',
        'mls',
        'fa cup',
        'em (frauen)',
        'uefa youth league',
        'fifa klub-wm',
        'confederations cup',
        'copa américa',
        'em-qualifikation',
    ],
    'darts-sport': [
        'rangliste',
        'darts-wm',
        'premier league darts',
        'grand slam of darts',
        'european championship',
        'world grand prix',
        'world matchplay',
        'players championship finals',
        'world series finals',
        'masters',
        'world cup of darts',
        'uk open',
    ],
    handball: [
        'handball-em',
        'handball-bundesliga',
        'handball champions league',
        'handball-wm',
        'dhb-pokal',
        'handball-wm frauen',
        'ehf european league',
        'frauen-em',
        'frauen-mehr',
        'nationalteam',
        'handball bundesliga frauen',
    ],
    tennis: ['atp', 'grand slams', 'wta', 'davis cup', 'billie jean king cup'],
    wintersport: [
        'biathlon',
        'skispringen',
        'ski alpin',
        'nordische kombination',
        'langlauf',
        'bob',
        'rodeln',
        'eisschnelllauf',
    ],
    basketball: [
        'basketball-wm',
        'bbl',
        'euroleague',
        'bbl pokal',
        'basketball champions league',
        'basketball-em',
        'eurocup',
        'nationalteam',
    ],
    eishockey: ['eishockey-wm', 'del', 'deutschland cup', 'chl', 'nationalteam'],
    'us-sport': [
        'nfl',
        'nba',
        'nhl',
        'mlb',
        'xfl',
        'elf',
        'gfl',
        'college football',
        'college basketball',
    ],
    motorsport: [
        'formel 1',
        'motogp',
        'dtm',
        'wrc',
        'formel e',
        'formel 3',
        'formel 2',
        'adac gt masters',
        'wec',
        'indycar',
        'nascar',
        'adac gt4 germany',
        'porsche carrera cup',
        'porsche supercup',
        'extreme e',
        'wrx',
        'adac tcr germany',
        'adac formel 4',
    ],
}

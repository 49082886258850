import React, { FC, memo } from 'react'
import Link, { LinkProps } from 'next/link'
import Tab, { TabProps } from '@sport1/news-styleguide/Tab'

type WrappedTabProps = TabProps & LinkProps & { testID?: string }

const WrappedTab: FC<WrappedTabProps> = ({ href, ...rest }) => {
    if (href)
        return (
            <Link href={href} passHref legacyBehavior>
                <Tab {...rest} href={href} />
            </Link>
        )

    return <Tab {...rest} />
}

export default memo(WrappedTab)

import { TagSearchInputProps } from '@sport1/types/web'
import React, { useCallback } from 'react'
import DummySearchBar from '@sport1/news-styleguide/DummySearchBar'
import { useOverlay } from '@/utils/overlay/OverlayProvider'

interface SearchProps extends TagSearchInputProps {
    toggleMenu: () => void
}

const Search = ({ inputText, popularUrl, url, toggleMenu }: SearchProps) => {
    const { openOverlay } = useOverlay()
    const onSearchBarClick = useCallback(() => {
        openOverlay({
            type: 'search',
            content: { placeholder: inputText, searchUrl: url, popularUrl },
        })
        toggleMenu()
    }, [inputText, openOverlay, popularUrl, url, toggleMenu])
    return (
        <DummySearchBar
            placeholder={inputText}
            onPress={onSearchBarClick}
            testID="dummy-search-bar"
        />
    )
}

export default Search

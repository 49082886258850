import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import FontelloIcon from '@sport1/news-styleguide/FontelloIcon'
import Touchable from '@sport1/news-styleguide/Touchable'

import React, { FC } from 'react'

export const ArrowDropdown: FC<{
    setShowDropdown?: () => void
    showDropdown: boolean
    testID?: string
}> = ({ setShowDropdown, showDropdown, testID }) => (
    <Touchable onPress={setShowDropdown} testID={testID}>
        <NonFlexingContainer paddingX="spacing-6" height="44px" spaceX="standard" centerVertical>
            <FontelloIcon
                name={`${showDropdown ? 'scroll-top' : 'scroll-bottom'}`}
                fontSize="15px"
                color="white"
            />
        </NonFlexingContainer>
    </Touchable>
)

import React, { FC } from 'react'
import { LabelProps, PopularCategoryTagsProps, TagProps } from '@sport1/types/web'
import TagList from '@/components/TagList'
import { HEADLINE_TYPE_MAP } from '@/utils/content/constants'

type Props = PopularCategoryTagsProps & {
    onTagPress?: (tag: TagProps | LabelProps) => void
}

const PopularCategoryTags: FC<Props> = ({ popularCategories, onTagPress }) => (
    <>
        {popularCategories.map((category, index) => (
            <TagList
                key={`${category.headlineType}_${index}`}
                headline={HEADLINE_TYPE_MAP.get(category.headlineType)}
                content={category.popularTags}
                onTagPress={onTagPress}
            />
        ))}
    </>
)

export default PopularCategoryTags

import React, { ReactElement } from 'react'
import Touchable from '@sport1/news-styleguide/Touchable'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Font from '@sport1/news-styleguide/Font'
import { TagProps } from '@sport1/types/web'
import { GridProps } from '@sport1/react-elements/Types'
import { TabbarConfig } from '../TabbarConfig'
import { TabBarItemType } from '../TabbarTypes'
import nofollowExternal from '@/utils/url/nofollowExternal'
import { DropDownRenderItemProps } from '@/components/DropDownOverlay'
import { sport1CondensedBlackItalic } from '@/helpers/fonts'
import WrappedTab from '@/components/WrappedTab'

const createTabBarRuler = (): HTMLDivElement => {
    const ruler = window.document.createElement('div')
    ruler.style.width = TabbarConfig.RULER_WIDTH
    ruler.style.position = TabbarConfig.RULER_POSITION
    ruler.style.whiteSpace = TabbarConfig.RULER_WHITESPACE
    ruler.style.fontFamily = TabbarConfig.FONT_FAMILY
    ruler.style.fontSize = TabbarConfig.FONT_SIZE

    return ruler
}
const getRulerMaxIndex = (
    items: TabBarItemType[],
    ruler: HTMLDivElement,
    containerWidth: number,
    staticWidth: number,
    uppercase?: boolean
): number | undefined => {
    let maxIndex
    for (let i = 0; i < items.length; i++) {
        const title = uppercase ? items[i].title.toLocaleUpperCase() : items[i].title

        if (title && TabbarConfig.TAB_WIDTH_WITHOUT_CONTENT && staticWidth) {
            ruler.innerHTML += title

            const rulerWidth =
                staticWidth + ruler.offsetWidth + (i + 1) * TabbarConfig.TAB_WIDTH_WITHOUT_CONTENT

            if (containerWidth && rulerWidth > containerWidth) {
                maxIndex = i
                break
            }
        }
    }

    return maxIndex
}

type RenderTabsProps = {
    tabVariant?: 'primary' | 'secondary'
    tabBarItem: TabBarItemType
    isActive: boolean
    uppercase?: boolean
    onPress?: () => void
    innerTextWrapper?: GridProps
}

function renderTab({
    tabVariant = 'primary',
    tabBarItem,
    isActive,
    uppercase = true,
    onPress,
    innerTextWrapper,
}: RenderTabsProps): ReactElement {
    const { title, href, target } = tabBarItem
    return (
        <WrappedTab
            tabVariant={tabVariant}
            title={uppercase ? title.toLocaleUpperCase() : title}
            key={title}
            isActive={isActive}
            href={href}
            onPress={onPress}
            webRel={nofollowExternal}
            webTarget={target}
            innerTextWrapper={innerTextWrapper}
        />
    )
}

type RenderTabBarItemsProps = {
    tabVariant?: 'primary' | 'secondary'
    tabBarItems: TabBarItemType[]
    numberItemsRendered: number
    onTabPress: (index: number) => void
    selectedTab: number
    uppercase?: boolean
}

function renderTabBarItems({
    tabVariant = 'primary',
    numberItemsRendered,
    onTabPress,
    selectedTab,
    tabBarItems,
    uppercase = true,
}: RenderTabBarItemsProps) {
    const itemsToRender = tabBarItems.slice(0, numberItemsRendered)

    return itemsToRender.map((tabBarItem, idx) => {
        const isActive = idx === selectedTab
        return renderTab({
            tabVariant,
            tabBarItem,
            isActive,
            onPress: () => onTabPress(idx),
            uppercase,
        })
    })
}

const renderOverlayItem = ({
    tag,
    currentTagIndex,
    selectedItemIndex,
    href,
    onPress,
}: Omit<DropDownRenderItemProps, 'index'> & {
    currentTagIndex: number
    selectedItemIndex: number
    onPress?: () => void
    backgroundColor?: 'pearl' | 'onyx'
}): ReactElement => {
    const { title } = tag
    const isCurrentIndex = selectedItemIndex === currentTagIndex

    return (
        <Touchable
            key={`${tag.contextId + tag.title}-${tag.componentKey}`}
            href={href}
            onPress={onPress}
            activeOpacity={isCurrentIndex ? 1 : 0.7}
        >
            <NonFlexingContainer
                padding="spacing-6"
                borderTop={1}
                borderColor="#ffffffb3"
                backgroundColor={isCurrentIndex ? 'gray' : 'auto'}
            >
                <Font
                    fontVariant="Body-S"
                    fontFamilyVariant="Sport1-CondensedBlackItalic"
                    nextFontFamilyVariant={sport1CondensedBlackItalic.style.fontFamily}
                    uppercase
                    numberOfLines={1}
                    color="pearl"
                >
                    {title}
                </Font>
            </NonFlexingContainer>
        </Touchable>
    )
}

const getDropdownOverlayItems = (
    tabbarItems: TabBarItemType[],
    numberItemsRendered: number
): TagProps[] => {
    return [
        ...tabbarItems.slice(numberItemsRendered).map(
            item =>
                ({
                    contextType: 'TAG',
                    contextId: item.contextId || '',
                    title: item.title,
                    url: item.href,
                    urlType: 'INTERNAL',
                    href: item.href,
                }) as TagProps
        ),
    ] as TagProps[]
}

const getUrlLastSlug = (slug?: string | string[]): string => {
    if (Array.isArray(slug)) {
        return slug.slice(-1)[0]
    }
    return slug || ''
}

export {
    createTabBarRuler,
    getRulerMaxIndex,
    renderTab,
    renderTabBarItems,
    renderOverlayItem,
    getDropdownOverlayItems,
    getUrlLastSlug,
}
